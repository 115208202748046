import { useState, useEffect } from "react";
import { getDeployed, getBuilt } from "../utils";

export default function Debug() {
    const [versionsBuilt, setVersionsBuilt] = useState([]);
    const [versionsBuiltCustom, setVersionsBuiltCustom] = useState([]);
    const [versionsDeployed, setVersionsDeployed] = useState([]);
    const [version, setVersion] = useState([]);
    const [versionStaging, setVersionStaging] = useState([]);
    const [logs, setLogs] = useState([]);
    const [tagStatus, setTagStatus] = useState([]);
    const [serviceStatus, setServiceStatus] = useState([]);
    const [dataRunners, setDataRunners] = useState([]);
    const [fileRunners, setFileRunners] = useState([]);

    const resetTags = () => {
        console.log('resetting tags');
        fetch('/api/reset_tags', {
            method: 'POST',
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            document.location.reload();
        });
    };

    useEffect(() => {
        getDeployed().then((data) => {
            setVersionsDeployed(data);

            getBuilt().then((data) => {
                setVersionsBuilt(data);
            }).catch((err) => {
                console.log(err.message);
            });
        }).catch((err) => {
            console.log(err.message);
        });

        fetch('/api/custom/images')
            .then((response) => response.json())
            .then((data) => {
                setVersionsBuiltCustom(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        fetch('/api/version/prod')
            .then((response) => response.json())
            .then((data) => {
                setVersion(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        fetch('/api/version/staging')
            .then((response) => response.json())
            .then((data) => {
                setVersionStaging(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        fetch('/api/logs?page=1')
            .then((response) => response.json())
            .then((data) => {
                setLogs(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        fetch('/api/tag_status')
            .then((response) => response.json())
            .then((data) => {
                setTagStatus(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        fetch('/api/service_status')
            .then((response) => response.json())
            .then((data) => {
                setServiceStatus(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        fetch('/api/data_runners')
            .then((response) => response.json())
            .then((data) => {
                setDataRunners(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        fetch('/api/file_runners')
            .then((response) => response.json())
            .then((data) => {
                setFileRunners(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <div>
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Debug</h1>
                </div>
                <div className="flex-auto text-right"></div>
            </div>
            <div className="overflow-hidden p-3 min-w-full">
                <h2 className="text-xl font-bold text-gray-900">Version (current_version)</h2>
                <div className="text-sm">
                    {(version.length > 0)
                        ? <pre>{JSON.stringify(version, null, 4)}</pre>
                        : <p className="text-gray-500">No version</p>
                    }
                </div>

                <br /><br />

                <h2 className="text-xl font-bold text-gray-900">Staging Version (current_staging_version)</h2>
                <div className="text-sm">
                    {(versionStaging.length > 0)
                        ? <pre>{JSON.stringify(versionStaging, null, 4)}</pre>
                        : <p className="text-gray-500">No staging version</p>
                    }
                </div>

                <br /><br />

                <h2 className="text-xl font-bold text-gray-900">Built (built_versions)</h2>
                <div className="text-sm">
                    {(versionsBuilt.length > 0)
                        ? <textarea
                            rows="10"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={JSON.stringify(versionsBuilt, null, 4)} />
                        : <p className="text-gray-500">No versions built</p>
                    }
                </div>

                <br /><br />

                <h2 className="text-xl font-bold text-gray-900">Custom Built (custom_built_images)</h2>
                <div className="text-sm">
                    {(versionsBuiltCustom.length > 0)
                        ? <textarea
                            rows="10"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={JSON.stringify(versionsBuiltCustom, null, 4)} />
                        : <p className="text-gray-500">No custom versions built</p>
                    }
                </div>

                <br /><br />

                <h2 className="text-xl font-bold text-gray-900">Deployed (deployed_versions)</h2>
                <div className="text-sm">
                    {(versionsDeployed.length > 0)
                        ? <textarea
                            rows="10"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={JSON.stringify(versionsDeployed, null, 4)} />
                        : <p className="text-gray-500">No versions deployed</p>
                    }
                </div>

                <br /><br />

                <h2 className="text-xl font-bold text-gray-900">Logs (logs)</h2>
                <div className="text-sm">
                    {Object.keys(logs).length > 0
                        ? <textarea
                            rows="10"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={JSON.stringify(logs, null, 4)} />
                        : <p className="text-gray-500">No logs</p>
                    }
                </div>

                <br /><br />

                <h2 className="text-xl font-bold text-gray-900">
                    <div className="flex justify-between">
                        <div>
                            Tag Status (tag_status)
                        </div>
                        <div>
                            <button
                                className="ml-2 px-2.5 py-1.5 rounded-lg bg-red-500 text-white text-sm font-semibold mb-2"
                                onClick={() => {resetTags()}}>
                                Reset Tags
                            </button>
                        </div>
                    </div>
                </h2>
                <div className="text-sm">
                    {Object.keys(tagStatus).length > 0
                        ? <textarea
                            rows="10"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={JSON.stringify(tagStatus, null, 4)} />
                        : <p className="text-gray-500">No tag status</p>
                    }
                </div>

                <br /><br />

                <h2 className="text-xl font-bold text-gray-900">Service Status (service_status)</h2>
                <div className="text-sm">
                    {Object.keys(serviceStatus).length > 0
                        ? <textarea
                            rows="10"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={JSON.stringify(serviceStatus, null, 4)} />
                        : <p className="text-gray-500">No service status</p>
                    }
                </div>

                <br /><br />

                <h2 className="text-xl font-bold text-gray-900">Runners (data) (data_runners)</h2>
                <div className="text-sm">
                    {Object.keys(dataRunners).length > 0
                        ? <textarea
                            rows="10"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={JSON.stringify(dataRunners, null, 4)} />
                        : <p className="text-gray-500">No data runners</p>
                    }
                </div>

                <br /><br />

                <h2 className="text-xl font-bold text-gray-900">Runners (file) (file_runners)</h2>
                <div className="text-sm">
                    {Object.keys(fileRunners).length > 0
                        ? <textarea
                            rows="10"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={JSON.stringify(fileRunners, null, 4)} />
                        : <p className="text-gray-500">No file runners</p>
                    }
                </div>

                <br /><br />
            </div>
        </div>
    );
}
