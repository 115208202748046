import { useState, useEffect } from "react";
import Moment from "react-moment";
import Spinner from "./Spinner.js";

export default function BuiltImages(props) {
    const [spinner, setSpinner] = useState(true);

    const handleDeployClick = (selectedValue) => {
        console.log('Selected value:', selectedValue);
        const id = 'btn-' + selectedValue;
        var button = document.getElementById(id);

        if (window.confirm('Are you sure you want to deploy ' + selectedValue + '?')) {
            button.innerHTML = 'Deploying...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-green-500');

            fetch('/api/deploy/' + selectedValue, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Deploy response: ', response);
            }).catch((err) => {
                console.log(err.message);
            });

            return true;
        }

        return false;
    };

    useEffect(() => {
        if (props.versionsBuilt.length > 0) {
            setSpinner(false);
        }
    }, [props.versionsBuilt]);

    return (
        <div>
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Built Images</h1>
                </div>
                <div className="flex-auto text-right"></div>
            </div>
            <div className="overflow-hidden p-3 min-w-full">
                {spinner
                    ? <Spinner />
                    : <table className="min-w-full border">
                        <thead className="border-b bg-black text-white">
                            <tr>
                                <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left" width="10%">
                                    Version
                                </th>
                                <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                    Image Built
                                </th>
                                <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left" width="10%">

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.versionsBuilt.map((v, index) => {
                                if (!props.versions.includes(v.tags[0])) {
                                    return (
                                        <tr className={`border-b`} key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                <a
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="text-black-500 hover:text-green-700 font-bold"
                                                    href={`https://github.com/Locally-com/core/compare/${props.version}...${v.tags[0]}`}>
                                                    {v.tags[0]}
                                                </a>
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                <Moment withTitle={v.timestamp.datetime} fromNow>
                                                    {v.timestamp.datetime}
                                                </Moment>
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {v.status !== undefined && v.status.deploying !== undefined && v.status.deploying
                                                    ? <button
                                                        id={`btn-${v.tags[0]}`}
                                                        className="bg-gray-500 rounded-full text-white font-bold py-2 px-4"
                                                        disabled>
                                                        Deploying...
                                                    </button>
                                                    : <button
                                                        id={`btn-${v.tags[0]}`}
                                                        className="bg-green-500 hover:bg-green-700 rounded-full text-white font-bold py-2 px-4"
                                                        onClick={() => {handleDeployClick(v.tags[0])}}>
                                                        Deploy Image
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    );
                                }

                                return null;
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
}
