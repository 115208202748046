import { useState, useEffect } from "react";
import Spinner from "./Spinner";

const Domains = () => {
    const [domains, setDomains] = useState([]);
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        fetch('/api/domains')
            .then((response) => response.json())
            .then((data) => {
                setDomains(data);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    if (!spinner && domains.length > 0) {
        return (
            <div className="logs-container">
                <div className="flex justify-between p-3">
                    <div className="flex-auto">
                        <h1 className="text-2xl font-bold text-gray-900">Domains</h1>
                    </div>
                    <div className="flex-auto text-right"></div>
                </div>
                <div>
                    <div className="overflow-hidden p-3">
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Domain
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Expiration Date
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Days Until
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {domains.map((d, index) => {
                                    return (
                                        <tr className="border-b" key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {d.domain}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {d.expiration_date}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {(d.days_left < 30)
                                                    ? <span className="bg-red-900 text-white font-bold p-1">{d.days_left}</span>
                                                    : d.days_left
                                                }
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="logs-container">
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Domains</h1>
                </div>
                <div className="flex-auto text-right"></div>
            </div>
            <div>
                <div className="overflow-hidden p-3">
                    {(spinner)
                        ? <Spinner />
                        : "No Domains Found"
                    }
                </div>
            </div>
        </div>
    )
};

export default Domains;
