import { useState } from "react";
import ScaleModal from "./ScaleModal";
import Spinner from "./Spinner";
import Moment from "react-moment";

export default function DeployedTags(props) {
    const [podCounts, setPodCounts] = useState({});
    const [scaleVersion, setScaleVersion] = useState('');
    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
    };

    const handleDeleteDeploy = (selectedValue) => {
        console.log('Selected value:', selectedValue);

        const id = 'dbtn-' + selectedValue;
        var button = document.getElementById(id);

        if (window.confirm('Are you sure you want to delete ' + selectedValue + '?')) {
            button.innerHTML = 'Deleting...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-red-500');

            fetch('/api/delete/deploy/' + selectedValue, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Deleting deploy response: ', response);

                setTimeout(() => {
                    document.location.reload();
                }, 30000); // wait and then reload
            }).catch((err) => {
                console.log(err.message);
            });
        }
    };

    const handleScaleClick = (selectedValue, counts) => {
        console.log('Selected value:', selectedValue);
        console.log('Counts:', counts);
        setScaleVersion(selectedValue);
        setPodCounts(counts);
        setShowModal(true);
    };

    const handleScaleClickSave = (scaleVersion) => {
        console.log('Scale version:', scaleVersion);

        const id = 'sbtn-' + scaleVersion;
        var button = document.getElementById(id);

        var api = document.getElementById('api').value || "1";
        var headless = document.getElementById('headless').value || "10";
        var hosted = document.getElementById('hosted').value || "10";
        var product = document.getElementById('product').value || "5";
        var staging = document.getElementById('staging').value || "1";
        var station = document.getElementById('station').value || "1";
        var stores = document.getElementById('stores').value || "20";
        var web = document.getElementById('web').value || "10";

        if (window.confirm('Are you sure you want to scale ' + scaleVersion + '?')) {
            button.innerHTML = 'Scaling...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-orange-500');

            fetch('/api/scale/' + scaleVersion, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    api: parseInt(api),
                    headless: parseInt(headless),
                    hosted: parseInt(hosted),
                    product: parseInt(product),
                    staging: parseInt(staging),
                    station: parseInt(station),
                    stores: parseInt(stores),
                    web: parseInt(web),
                })
            }).then((response) => {
                console.log('Scaling response: ', response);
            }).catch((err) => {
                console.log(err.message);
            });
        }

        closeModal();
    };

    const handlePromoteClick = (selectedValue) => {
        console.log('Selected value:', selectedValue);

        const id = 'pbtn-' + selectedValue;
        var button = document.getElementById(id);

        if (window.confirm('Are you sure you want to promote ' + selectedValue + ' to production?')) {
            button.innerHTML = 'Promoting...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-yellow-500');

            fetch('/api/promote/' + selectedValue, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => response.json())
            .then((data) => {
                console.log('Promote response: ', data);

                if (data.status === 'success') {
                    setTimeout(() => {
                        document.location.reload();
                    }, 5000); // wait 5 seconds and then reload
                } else {
                    if (data.error.length > 0) {
                        alert('Promote failed: ' + data.error);
                    }
                }
            }).catch((err) => {
                console.log(err.message);
            });

            return true;
        }

        return false;
    };

    const handlePromoteStagingClick = (selectedValue) => {
        console.log('Selected value:', selectedValue);

        const id = 'stgbtn-' + selectedValue;
        var button = document.getElementById(id);

        if (window.confirm('Are you sure you want to promote ' + selectedValue + ' to staging?')) {
            button.innerHTML = 'Staging...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-green-500');

            fetch('/api/promote/staging/' + selectedValue, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Promote (staging) response: ', response);

                if (response.status === 200) {
                    setTimeout(() => {
                        document.location.reload();
                    }, 5000); // wait 5 seconds and then reload
                }
            }).catch((err) => {
                console.log(err.message);
            });

            return true;
        }

        return false;
    };

    const handleWarmupClick = (selectedValue) => {
        console.log('Selected value:', selectedValue);

        const id = 'wbtn-' + selectedValue;
        var button = document.getElementById(id);

        if (window.confirm('Are you sure you want to warmup ' + selectedValue + '?')) {
            button.innerHTML = 'Warming...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-green-500');

            fetch('/api/warmup/' + selectedValue, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Warmup response: ', response);
            }).catch((err) => {
                console.log(err.message);
            });

            return true;
        }

        return false;
    };

    return (
        <div>
            <ScaleModal
                version={scaleVersion}
                showModal={showModal}
                handleScaleClickSave={handleScaleClickSave}
                podCounts={podCounts}
                closeModal={closeModal} />
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Deployed Tags</h1>
                </div>
                <div className="flex-auto text-right"></div>
            </div>
            <div className="overflow-hidden p-3 min-w-full">
                {props.spinner
                    ? <Spinner />
                    : <table className="min-w-full border">
                        <thead className="border-b bg-black text-white">
                            <tr>
                                <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left" width="10%">
                                    Version
                                </th>
                                <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                    Deployed
                                </th>
                                <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                    Pods
                                </th>
                                <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.versionsDeployed.map((v, index) => {
                                var bg = (index % 2 === 0 && v.version !== props.version) ? 'bg-gray-100' : '';
                                // detect daylight savings time
                                var hours = new Date().getTimezoneOffset() / 60;
                                return (
                                    <tr className={`border-b ${v.version === props.version ? 'prod' : ''} ${bg}`} key={index}>
                                        <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className="text-black-500 hover:text-green-700 font-bold"
                                                href={`https://github.com/Locally-com/core/compare/${props.version}...${v.version}`}>
                                                {v.version}
                                            </a>
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                            <Moment withTitle={v.timestamp} fromNow subtract={{ hours: hours }}>
                                                {v.timestamp}
                                            </Moment>
                                        </td>
                                        <td className="text-sm text-gray-900 px-3 py-2 whitespace-nowrap border-r">
                                            <div className="grid grid-cols-3 gap-2">
                                            {v.pods.api.current !== undefined && (
                                                <div><strong>* api:</strong> {v.pods.api.current} of {v.pods.api.desired}</div>
                                            )}
                                            {v.pods.headless.current !== undefined && (
                                                <div><strong>* headless:</strong> {v.pods.headless.current} of {v.pods.headless.desired}</div>
                                            )}
                                            {v.pods.hosted.current !== undefined && (
                                                <div><strong>* hosted:</strong> {v.pods.hosted.current} of {v.pods.hosted.desired}</div>
                                            )}
                                            {v.pods.product.current !== undefined && (
                                                <div><strong>* product:</strong> {v.pods.product.current} of {v.pods.product.desired}</div>
                                            )}
                                            {v.pods.station.current !== undefined && (
                                                <div><strong>* station:</strong> {v.pods.station.current} of {v.pods.station.desired || 1}</div>
                                            )}
                                            {v.pods.staging.current !== undefined && (
                                                <div><strong>* staging:</strong> {v.pods.staging.current} of {v.pods.staging.desired}</div>
                                            )}
                                            {v.pods.stores.current !== undefined && (
                                                <div><strong>* stores:</strong> {v.pods.stores.current} of {v.pods.stores.current}</div>
                                            )}
                                            {v.pods.web.current !== undefined && (
                                                <div><strong>* web:</strong> {v.pods.web.current} of {v.pods.web.desired}</div>
                                            )}
                                            </div>
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                            {(props.version !== v.version)
                                                ? (v.pods.ready) // if all pods are ready
                                                    ?
                                                        <div>
                                                            {v.status.promoting
                                                                ? <button
                                                                    id={`pbtn-${v.version}`}
                                                                    className="bg-gray-500 rounded-full text-white font-bold py-2 px-4"
                                                                    disabled>
                                                                    Promoting...
                                                                </button>
                                                                : <button
                                                                    id={`pbtn-${v.version}`}
                                                                    className="bg-yellow-500 hover:bg-yellow-700 rounded-full text-white font-bold py-2 px-4"
                                                                    onClick={() => {handlePromoteClick(v.version)}}>
                                                                    Promote
                                                                </button>
                                                            }
                                                            {props.versionStaging !== v.version &&
                                                                <button
                                                                    id={`stgbtn-${v.version}`}
                                                                    className="bg-blue-500 hover:bg-blue-700 rounded-full text-white font-bold py-2 px-4 ml-2"
                                                                    onClick={() => {handlePromoteStagingClick(v.version)}}>
                                                                    Promote to Staging
                                                                </button>
                                                            }
                                                            {(props.version !== v.version) &&
                                                                v.status.deleting
                                                                    ? <button
                                                                        id={`dbtn-${v.version}`}
                                                                        className="bg-gray-500 rounded-full text-white font-bold py-2 px-4"
                                                                        disabled>
                                                                        Deleting...
                                                                    </button>
                                                                    : <button
                                                                        id={`dbtn-${v.version}`}
                                                                        className="bg-red-500 hover:bg-red-700 rounded-full text-white font-bold py-2 px-4 ml-2"
                                                                        onClick={() => {handleDeleteDeploy(v.version)}}>
                                                                        Delete
                                                                    </button>
                                                            }
                                                        </div>
                                                    : // not all pods are ready
                                                        <div>
                                                            {v.status.warming
                                                                ? <button
                                                                    id={`wbtn-${v.version}`}
                                                                    className="bg-gray-500 rounded-full text-white font-bold py-2 px-4">
                                                                    Warming...
                                                                </button>
                                                                : <button
                                                                    id={`wbtn-${v.version}`}
                                                                    className="bg-green-500 hover:bg-green-700 rounded-full text-white font-bold py-2 px-4"
                                                                    onClick={() => {handleWarmupClick(v.version)}}>
                                                                    Warmup
                                                                </button>
                                                            }
                                                            {v.status.scaling
                                                                ? <button
                                                                    id={`sbtn-${v.version}`}
                                                                    className="bg-gray-500 rounded-full text-white font-bold py-2 px-4 ml-2"
                                                                    disabled>
                                                                    Scaling...
                                                                </button>
                                                                : <button
                                                                    id={`sbtn-${v.version}`}
                                                                    className="bg-orange-500 hover:bg-orange-700 rounded-full text-white font-bold py-2 px-4 ml-2"
                                                                    onClick={() => {handleScaleClick(v.version, v.pods)}}>
                                                                    Scale
                                                                </button>
                                                            }
                                                            {props.versionStaging !== v.version &&
                                                                <button
                                                                    id={`stgbtn-${v.version}`}
                                                                    className="bg-blue-500 hover:bg-blue-700 rounded-full text-white font-bold py-2 px-4 ml-2"
                                                                    onClick={() => {handlePromoteStagingClick(v.version)}}>
                                                                    Promote to Staging
                                                                </button>
                                                            }
                                                            {props.version !== v.version &&
                                                                <button
                                                                    id={`dbtn-${v.version}`}
                                                                    className="bg-red-500 hover:bg-red-700 rounded-full text-white font-bold py-2 px-4 ml-2"
                                                                    onClick={() => {handleDeleteDeploy(v.version)}}>
                                                                    Delete
                                                                </button>
                                                            }
                                                        </div>
                                                : <div>
                                                    {v.status.scaling
                                                        ? <button
                                                            id={`sbtn-${v.version}`}
                                                            className="bg-gray-500 rounded-full text-white font-bold py-2 px-4"
                                                            disabled>
                                                            Scaling...
                                                        </button>
                                                        : <button
                                                            id={`sbtn-${v.version}`}
                                                            className="bg-orange-500 hover:bg-orange-700 rounded-full text-white font-bold py-2 px-4"
                                                            onClick={() => {handleScaleClick(v.version, v.pods)}}>
                                                            Scale
                                                        </button>
                                                    }
                                                    {props.versionStaging !== v.version &&
                                                        <button
                                                            id={`stgbtn-${v.version}`}
                                                            className="bg-blue-500 hover:bg-blue-700 rounded-full text-white font-bold py-2 px-4 ml-2"
                                                            onClick={() => {handlePromoteStagingClick(v.version)}}>
                                                            Promote to Staging
                                                        </button>
                                                    }
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
}
