export function isLoggedIn() {
    console.log('Checking if logged in');
    return fetch('/auth/loggedin')
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
}

export function killAllTimers() {
    console.log('Killing all timers');
    var highestTimeoutId = setTimeout(undefined);
    for (var i = 0 ; i < highestTimeoutId ; i++) {
        clearTimeout(i); 
    }
}

export function getDeployed() {
    return fetch('/api/versions/deployed')
        .then((response) => response.json())
        .then((data) => {
            var vers = [];
            // loop over data response and get tags
            for (var i = 0; i < data.length; i++) {
                if (data[i].version !== undefined) {
                    vers.push(data[i].version);
                }
            }

            data.vers = vers;
            return data;
        })
        .catch((err) => {
            console.log(err.message);
        });
};

export function getBuilt() {
    return fetch('/api/versions/built')
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.log(err.message);
        });
};
