import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isLoggedIn } from "./utils";

export default function Login() {
    const params = useLocation().search;
    const error = new URLSearchParams(params).get('error');

    const [authUrl, setAuthUrl] = useState(null);
    useEffect(() => {
        isLoggedIn().then((loggedIn) => {
            console.log('Logged in: ', loggedIn);
            if (loggedIn.logged_in) {
                window.location.href = '/dashboard';
            }
        });

        fetch('/auth/google')
            .then((response) => response.json())
            .then((data) => {
                setAuthUrl(data.url);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    if (authUrl === null) {
        return (
            <div className="text-center">
                <h1 className="text-2xl font-bold text-gray-900">Loading...</h1>
            </div>
        )
    }

    return(
        <section className="h-full gradient-form bg-gray-200 md:h-screen">
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Not Authorized!</strong>
                    <span className="block sm:inline pl-3">{error}</span>
                </div>
            )}
            <div className="flex justify-center items-center flex-wrap h-full text-gray-800">
                <div className="flex bg-white shadow-lg rounded-lg p-8">
                    <div className="flex-auto p-5">
                        <img src="https://www.locally.com/img/locally-avatar.png" width="150" alt="Logo" className="p-5" />
                        <hr />
                        <h2 className="text-lg font-bold text-center mb-4 p-3">SRE | Panel</h2>
                    </div>
                    <div className="flex-auto p-5">
                        <br /><br />
                        <form>
                            <p className="mb-4">Please login to your account</p>
                            <div className="text-center pt-1 pb-1">
                                <a
                                    href={authUrl}
                                    className="bg-green-500 inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3"
                                    type="button">
                                    Log in with Google
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
