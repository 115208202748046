import { useState, useEffect } from "react";
import Moment from "react-moment";
import Spinner from "./Spinner";

const Queues = () => {
    const [semaphores, setSemaphores] = useState([]);
    const [spinner, setSpinner] = useState(true);
    useEffect(() => {
        fetch('/api/semaphores')
            .then((response) => response.json())
            .then((data) => {
                data.forEach((semaphore, index) => {
                    if (semaphore.label.includes(['audit:lightspeed', 'geocode:all-cron'])) {
                        // these labels should be ignored
                        delete data[index];
                    }
                });

                setSemaphores(data);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    const canUnlock = "Are you sure you want to unlock this semaphore?";
    const noSemaphores = (!spinner)
        ? 'No locked semaphores found.'
        : <Spinner />

    return (
        <div className="semaphores-container p-3">
            <br />
            <div className="flex justify-between mb-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Semaphores</h1>
                </div>
                <div className="flex-auto text-right">

                </div>
            </div>
            {(semaphores.length === 0)
                ? <div className="text-center">{noSemaphores}</div>
                : <div>
                    <div className="overflow-hidden p-3">
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Label
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        ID
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Identifier
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Locked Duration
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {semaphores.map((semaphore, index) => {
                                    return (
                                        <tr className="border-b" key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {semaphore.label}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {semaphore.id}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {semaphore.identifier}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                <Moment fromNow>{semaphore.updated_at.date}</Moment>
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                <button
                                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                    onClick={() => {if (window.confirm(canUnlock)) {DoUnlock(semaphore.id)}}}>
                                                    Unlock
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
};

function DoUnlock(id) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    fetch('/api/clear-semaphore/' + id, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            if (data.result === 'ok') {
                window.location.reload();
            } else {
                alert('There was an error unlocking the semaphore.');
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
}

export default Queues;
