import { useEffect, useState } from "react";
import Spinner from "./Spinner";

const CustomBuildDeploy = () => {
    const [githubPulls, setGithubPulls] = useState([]);
    const [builtCustomImages, setBuiltCustomImages] = useState([]);
    const [builtCustomImagesKeys, setBuiltCustomImagesKeys] = useState([]);
    const [colorsAvailable] = useState(['blue', 'green']);
    const [activeColors, setActiveColors] = useState([]);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedPull, setSelectedPull] = useState('');
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        const fetchAll = () => {
            fetch('/api/github_pulls')
                .then((response) => response.json())
                .then((pulls) => {
                    setGithubPulls(pulls);

                    var activeColors = [];
                    colorsAvailable.forEach((color) => {
                        fetch(`/api/color/${color}`)
                            .then((response) => response.json())
                            .then((data) => {
                                var version = data.version.toLowerCase();
                                var color = data.color;

                                // "Active Deployments" section
                                activeColors.push({
                                    version: version,
                                    color: color,
                                    updated_at: data.updated_at,
                                });

                                // "Github Pull Requests" section
                                setGithubPulls((prevPulls) => {
                                    var newPulls = prevPulls.map((pull) => {
                                        if (pull.ref.toLowerCase() === version) {
                                            return {
                                                ...pull,
                                                color: color,
                                            };
                                        }

                                        return pull;
                                    });

                                    return newPulls;
                                });

                                // Get all the custom images that have been built
                                fetch('/api/custom/images')
                                    .then((response) => response.json())
                                    .then((data) => {
                                        setBuiltCustomImages(data);
                                    })
                                    .catch((err) => {
                                        console.log(err.message);
                                    });

                                // "Promote a Custom Image" section
                                setBuiltCustomImages((prevImages) => {
                                    var newImages = prevImages.map((image) => {
                                        if (image.tags[0].toLowerCase() === version) {
                                            return {
                                                ...image,
                                                color: color,
                                            };
                                        }

                                        return image;
                                    });

                                    // Set the keys of the custom images that have been built
                                    setBuiltCustomImagesKeys(newImages.map((image) => image.tags[0].toLowerCase()));

                                    return newImages;
                                });

                                setActiveColors(activeColors);
                                setSpinner(false);
                            }).catch((err) => {
                                console.log(err.message);
                            });
                    });
                })
                .catch((err) => {
                    console.log(err.message);
                });
        };

        fetchAll(); // Initial fetch
        return () => {
            setGithubPulls([]);
            setBuiltCustomImages([]);
        };
    }, [colorsAvailable]);

    const deployCustomImage = (selectedValue) => {
        const id = 'dbtn-' + selectedValue;
        var button = document.getElementById(id);

        if (window.confirm('Are you sure you want to build a custom image for ' + selectedValue + '?')) {
            button.innerHTML = 'Building...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-yellow-500');

            fetch('/api/custom/build/' + selectedValue, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => response.json())
            .then((data) => {
                console.log('Custom Build Image response: ', data);

                if (data.status === 'success') {
                    setTimeout(() => {
                        document.location.reload();
                    }, 60000); // wait and then reload
                } else {
                    if (data.error.length > 0) {
                        alert('Custom Build Image failed: ' + data.error);
                    }
                }
            }).catch((err) => {
                console.log(err.message);
            });

            return true;
        }

        return false;
    };

    const runColorDeploy = (selectedColor, selectedPull) => {
        if (selectedPull === '' || selectedColor === '') {
            alert('Please select a PR and a color to deploy');
            return false;
        }

        const id = 'deployColorButton';
        var button = document.getElementById(id);

        if (window.confirm('Are you sure you want to promote ' + selectedPull + ' to ' + selectedColor + '?')) {
            button.innerHTML = 'Promoting...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-yellow-500');

            fetch('/api/custom/promote/' + selectedColor + '/' + selectedPull, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => response.json())
            .then((data) => {
                console.log('Promote response: ', data);

                if (data.status === 'success') {
                    setTimeout(() => {
                        document.location.reload();
                    }, 25000); // wait and then reload
                } else {
                    if (data.error.length > 0) {
                        alert('Promote failed: ' + data.error);
                    }
                }
            }).catch((err) => {
                console.log(err.message);
            });

            return true;
        }

        return false;
    };

    const handleDeleteDeploy = (color, selectedValue) => {
        console.log('Selected value:', selectedValue);

        const id = 'dbtn-' + selectedValue;
        var button = document.getElementById(id);

        if (window.confirm('Are you sure you want to remove ' + selectedValue + ' from ' + color + '?')) {
            button.innerHTML = 'Deleting...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-red-500');

            fetch('/api/custom/promote/' + color + '/none', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                } // Missing parenthesis is fixed here
            })
            .then((response) => response.json())
            .then((data) => {
                console.log('Deleting deploy response: ', data); // Fix 'response' to 'data'

                if (data.status === 'success') {
                    setTimeout(() => {
                        document.location.reload();
                    }, 15000); // wait and then reload
                } else {
                    if (data.error.length > 0) {
                        alert('Promote failed: ' + data.error);
                    }
                }
            }).catch((err) => {
                console.log(err.message);
            });
        }
    };

    console.log('custom image keys:', builtCustomImagesKeys);

    if (spinner) {
        return (
            <div className="logs-container">
                <div className="flex justify-between p-3">
                    <div className="flex-auto">
                        <h1 className="text-2xl font-bold text-gray-900">Custom Deployment</h1>
                    </div>
                    <div className="flex-auto text-right"></div>
                </div>
                <div className="overflow-hidden p-3">
                    <Spinner />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="overflow-hidden p-3 min-w-full">
                <div className="flex justify-between p-3 pl-0">
                    <div className="flex-auto">
                        <h1 className="text-2xl font-bold text-gray-900">Custom Deployment</h1>
                    </div>
                    <div className="flex-auto text-right"></div> 
                </div>

                <hr className="my-4" />

                <div className="flex justify-between p-3 pl-0">
                    <div className="flex-initial">
                        <h1 className="text-2xl font-bold text-gray-900 mb-3">
                            Promote a Custom Image
                        </h1>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Deploy a custom image
                            </label>
                            <select
                                onChange={(e) => setSelectedPull(e.target.value)}
                                className="bg-white border border-gray-400 rounded px-3 py-2 mb-3">
                                <option value="">Select an Image</option>
                                {builtCustomImages.map((image) => (
                                    <option key={image.digest} value={image.tags[0]} disabled={image.color !== undefined}>
                                        {image.tags[0]} {image.color !== undefined ? ` (Deployed to ${image.color})` : ''}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                to the color
                            </label>
                            <select
                                onChange={(e) => setSelectedColor(e.target.value)}
                                className="bg-white border border-gray-400 rounded px-3 py-2 mb-3">
                                <option value="">Select a Color</option>
                                {colorsAvailable.map((color) => (
                                    <option key={color} value={color}>{color}</option>
                                ))}
                            </select>
                        </div>

                        <hr className="my-4" />

                        <button
                            id="deployColorButton"
                            onClick={() => {runColorDeploy(selectedColor, selectedPull)}}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Deploy to Color
                        </button>
                    </div>
                    <div className="flex-auto ml-8">
                        <h1 className="text-2xl font-bold text-gray-900 mb-3">
                            Active Deployments
                        </h1>

                        <table className="min-w-full border text-sm">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Color
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Pull Request
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {activeColors
                                    .filter((color) => color.version !== 'none') // Filter out colors where version is 'none'
                                    .map((color) => (
                                        <tr className={`border-b`} key={color.version}>
                                            <td className="border px-4 py-2">
                                                <a href={`https://www.locally-${color.color}.com`} target="_blank" rel="noreferrer">
                                                    <span className={`bg-${color.color}-400 rounded p-2 text-white`}>
                                                        {color.color}
                                                    </span>
                                                </a>
                                            </td>
                                            <td className="border px-4 py-2">
                                                {color.version}
                                            </td>
                                            <td className="border px-4 py-2">
                                                <button
                                                    id={`dbtn-${color.version}`}
                                                    onClick={() => { handleDeleteDeploy(color.color, color.version) }}
                                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    Delete Custom Deployment
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex justify-between p-3 pl-0 pt-5">
                    <div className="flex-auto">
                        <h1 className="text-2xl font-bold text-gray-900">Github Pull Requests</h1>
                    </div>
                    <div className="flex-auto text-right"></div>
                </div>
                <table className="min-w-full border text-sm">
                    <thead className="border-b bg-black text-white">
                        <tr>
                            <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                Updated
                            </th>
                            <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                PR
                            </th>
                            <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {githubPulls.map((pull) => (
                            <tr className={`border-b`} key={pull.id}>
                                <td className="border px-4 py-2">{pull.updated_at}</td>
                                <td className="border px-4 py-2">
                                    <a href={pull.html_url} target="_blank" rel="noreferrer">
                                        {pull.ref}
                                    </a>
                                    <p className="text-xs text-slate-400">{pull.user}</p>
                                </td>
                                <td className="border px-4 py-2">
                                    {!builtCustomImagesKeys.includes(pull.ref.toLowerCase()) ? (
                                        <button
                                            id={`dbtn-${pull.ref}`}
                                            onClick={() => {deployCustomImage(pull.ref)}}
                                            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
                                            Build Image
                                        </button>
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default CustomBuildDeploy;
