import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const LogsAll = () => {
    const params = useLocation().search;
    const page = new URLSearchParams(params).get('page') || 1;

    const [logs, setLogs] = useState([]);
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        console.log('page: ' + page);
        fetch('/api/all-logs?page=' + page)
            .then((response) => response.json())
            .then((data) => {
                setLogs(data);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [page, spinner]);

    const noLogs = (!spinner)
        ? 'No logs found.'
        : 'Loading logs...'

    return (
        <div className="queues-container">
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Logs</h1>
                </div>
                <div className="flex-auto text-right">
                    Page {logs.current_page} of {logs.last_page}
                </div>
            </div>
            {(logs.length === 0)
                ? <div className="text-center">{noLogs}</div>
                : <div>
                    <div className="overflow-hidden p-3">
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Date
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Email
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Message
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.data.map((log, index) => {
                                    var bg = (index % 2 === 0) ? 'bg-gray-100' : '';

                                    return (
                                        <tr className={`border-b ${bg}`} key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {log.created_at}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {log.email || '--'}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {log.message}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="3" className="text-sm font-medium px-3 py-2 text-right bg-black">
                                        <nav aria-label="Page navigation">
                                            <ul className="inline-flex -space-x-px">
                                                {logs.current_page > 1 && (
                                                    <li>
                                                        <Link
                                                            to={"/logs-all?page=" + (logs.current_page - 1)}
                                                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                                            Previous
                                                        </Link>
                                                    </li>
                                                )}
                                                {logs.current_page <= logs.last_page && logs.last_page !== logs.current_page && (
                                                    <li>
                                                        <Link
                                                            to={"/logs-all?page=" + (logs.current_page + 1)}
                                                            className="inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                                            Next
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </nav>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
};

export default LogsAll;
