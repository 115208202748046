import { useState, useEffect } from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import QueueForm from "./QueueForm.js";
import Spinner from "./Spinner.js";
import { usePrevious } from '@react-hooks-library/core'

const Queues = () => {
    const [queues, setQueues] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const previousQueues = usePrevious(queues);

    useEffect(() => {
        var methods = () => {
            fetch('/api/queues')
                .then((response) => response.json())
                .then((data) => {
                    setQueues(data);
                    setSpinner(false);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        };

        methods(); // Initial fetch
        const intervalId = setInterval(methods, 5000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const noQueues = (!spinner)
        ? 'No queues found.'
        : <Spinner />

    return (
        <div className="queues-container">
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Queues</h1>
                </div>
                <div className="flex-auto text-right">
                    {
                        (queues.length > 0)
                            ? <QueueForm queues={queues} />
                            : null
                    }
                </div>
            </div>
            {(queues.length === 0)
                ? <div className="text-center">{noQueues}</div>
                : <div>
                    <div className="overflow-hidden p-3">
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Name
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Runner Type
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Default Limit
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Current Limit
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Current Jobs
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {queues.map((queue, index) => {
                                    return (
                                        <tr className="border-b" key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {queue.NAME}
                                                {
                                                    (queue['HI-PRI'] === 'YES')
                                                        ? <span className="text-red-500 p-1 ml-2">(High Priority)</span>
                                                        : null
                                                }
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {queue.TYPE}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {queue.LIMIT}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {
                                                    (queue.CURR === '0')
                                                        ? <span className="bg-red-500 text-white p-1">{queue.CURR}</span>
                                                        : (queue.CURR !== queue.LIMIT.toString())
                                                            ? <span className="bg-yellow-500 p-1">{queue.CURR}</span>
                                                            : <span>{queue.CURR}</span>
                                                }
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                <div className="flex">
                                                    <div className="flex-auto">
                                                        {queue.QUEUED.toLocaleString()}
                                                    </div>
                                                    <div className="flex-none text-right">
                                                        {
                                                            (queue.QUEUED
                                                                && previousQueues
                                                                && previousQueues[index]
                                                                && previousQueues[index].QUEUED
                                                                && queue.QUEUED > previousQueues[index].QUEUED)
                                                                ? <div className="flex text-red-500 justify-end">
                                                                    <div>
                                                                        +{queue.QUEUED - previousQueues[index].QUEUED}
                                                                    </div>
                                                                    <div className="ml-1">
                                                                        <FaArrowUp title="Jobs trending upward"/>
                                                                    </div>
                                                                </div>
                                                                : (queue.QUEUED
                                                                    && previousQueues
                                                                    && previousQueues[index]
                                                                    && previousQueues[index].QUEUED
                                                                    && queue.QUEUED > 0
                                                                    && queue.QUEUED < previousQueues[index].QUEUED)
                                                                    ? <div className="flex text-green-500 justify-end">
                                                                        <div>
                                                                            -{previousQueues[index].QUEUED - queue.QUEUED}
                                                                        </div>
                                                                        <div className="ml-1">
                                                                            <FaArrowDown title="Jobs trending downward" />
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                            {/* <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                <button
                                                    className="bg-blue-500 hover:bg-blue-700 rounded-full text-white font-bold py-2 px-4 mr-2"
                                                    disabled>Off</button>
                                                <button
                                                    className="bg-red-500 hover:bg-red-700 rounded-full text-white font-bold py-2 px-4 mr-2"
                                                    disabled>Flush</button>
                                            </td> */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
};

export default Queues;
