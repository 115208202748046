import React from 'react';
import { createRoot } from 'react-dom/client';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import Root from './Root.js';
import Queues from './components/Queues.js';
import Home from './components/Home.js';
import Semaphores from './components/Semaphores.js';
import Login from './Login.js';
import LogsAll from './components/LogsAll.js';
import Domains from './components/Domains.js';
import Debug from './components/Debug.js';
import Crons from './components/Crons.js';
import SlowSQLLog from './components/SlowSQLLog.js';
import ServiceStatus from './components/ServiceStatus.js';
import Runners from './components/Runners.js';
import RedisReserved from './components/RedisReserved.js';
import CustomBuildDeploy from './components/CustomBuildDeploy.js';

const router = createHashRouter(
    [
        {
            path: '/',
            element: <Root />,
            errorElement: <div>404</div>,
            children: [
                {
                    path: '/',
                    element: <Home />,
                },
                {
                    path: 'custom',
                    element: <CustomBuildDeploy />,
                },
                {
                    path: 'queues',
                    element: <Queues />,
                },
                {
                    path: 'semaphores',
                    element: <Semaphores />,
                },
                {
                    path: 'logs-all',
                    element: <LogsAll />,
                },
                {
                    path: 'domains',
                    element: <Domains />,
                },
                {
                    path: 'debug',
                    element: <Debug />,
                },
                {
                    path: 'crons',
                    element: <Crons />,
                },
                {
                    path: 'mysql_slow_query_logs',
                    element: <SlowSQLLog />,
                },
                {
                    path: 'service_status',
                    element: <ServiceStatus />,
                },
                {
                    path: 'runners',
                    element: <Runners />,
                },
                {
                    path: 'redis_reserved',
                    element: <RedisReserved />,
                },
            ],
        },
        {
            path: '/login',
            element: <Login />,
        },
    ],
);

const root = createRoot(document.getElementById('app'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
