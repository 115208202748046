import BuiltImages from "./BuiltImages.js";
import DeployedTags from "./DeployedTags.js";
import Logs from "./Logs";
import Spinner from "./Spinner";

export default function Deploy(props) {
    if (props.version.length > 0 && props.versionStaging.length > 0) {
        return (
            <>
                <DeployedTags
                    version={props.version}
                    versionStaging={props.versionStaging}
                    versions={props.versions}
                    versionsDeployed={props.versionsDeployed} />

                <br />

                <div className="flex">
                    <div className="flex-none">
                        <BuiltImages
                            version={props.version}
                            versionStaging={props.versionStaging}
                            versions={props.versions}
                            versionsBuilt={props.versionsBuilt} />
                    </div>
                    <div className="flex-auto">
                        <Logs
                            logs={props.logs} />
                    </div>
                </div>
            </>
        );
    }

    return (
        <Spinner />
    );
}
