import { useState, useEffect } from "react";
import Spinner from "./Spinner";
import Moment from "react-moment";

const Runners = () => {
    const [dataRunners, setDataRunners] = useState([]);
    const [fileRunners, setFileRunners] = useState([]);

    const handleDataRunnerScale = () => {
        var button = document.getElementById('data-runner-scale-button');
        var input = document.getElementById('data-runner-scale');
        var selectedValue = input.value;
        if (selectedValue.length === 0) {
            return false;
        }

        if (window.confirm('Are you sure you want to scale the data runners to ' + selectedValue + '?')) {
            button.innerHTML = 'Scaling...';
            button.disabled = true;
            button.classList.add('bg-gray-500');
            button.classList.remove('bg-red-500');

            fetch('/api/data_runners/scale/' + selectedValue, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Scaling data runners response: ', response);

                if (response.status === 200) {
                    setTimeout(() => {
                        document.location.reload();
                    }, 10000); // wait 10 seconds and then reload
                }
            }).catch((err) => {
                console.log(err.message);
            });

            return true;
        }

        return false;
    };

    useEffect(() => {
        const runners = () => {
            fetch('/api/data_runners')
                .then((response) => response.json())
                .then((data) => {
                    setDataRunners(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });

            fetch('/api/file_runners')
                .then((response) => response.json())
                .then((data) => {
                    setFileRunners(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        };

        runners(); // Initial fetch
        const intervalId = setInterval(runners, 15000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if (dataRunners && dataRunners.items && dataRunners.items.length > 0 && fileRunners && fileRunners.items && fileRunners.items.length > 0) {
        return (
            <div className="logs-container">
                <div className="flex justify-between p-3">
                    <div className="flex-auto">
                        <h1 className="text-2xl font-bold text-gray-900">Runners</h1>
                    </div>
                    <div className="flex-auto text-right"></div>
                </div>
                <div>
                    <div className="overflow-hidden p-3">
                        <div className="flex">
                            <div className="flex-auto">
                                <h1 className="text-xl font-bold text-gray-900">
                                    File Runners
                                </h1>
                            </div>
                            <div className="text-gray-700 flex-none justify-end">
                                <label htmlFor="total">Total:</label>
                                <input
                                    className="border rounded py-2 px-3 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    defaultValue={fileRunners.items.length}
                                    size="5"
                                    disabled />
                            </div>
                        </div>
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Pod
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Version
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Started
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Restarts
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Phase
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {fileRunners.items.map((d, index) => {
                                    var started_at = (d.status.containerStatuses && d.status.containerStatuses[0])
                                        ? d.status.containerStatuses[0].state.running.startedAt
                                        : d.status.startTime;
                                    var restart_count = (d.status.containerStatuses && d.status.containerStatuses[0])
                                        ? d.status.containerStatuses[0].restartCount
                                        : 0;
                                    var phase = (d.status.phase === 'Failed')
                                        ? <span className="text-red-500">{d.status.phase}</span>
                                        : d.status.phase;
                                    return (
                                        <tr className="border-b" key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {d.metadata.name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {d.metadata.labels.version}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                <Moment withTitle={started_at} fromNow>
                                                    {started_at}
                                                </Moment>
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {restart_count}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {phase}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        <br /><br />

                        <div className="flex">
                            <div className="flex-auto">
                                <h1 className="text-xl font-bold text-gray-900">
                                    Data Runners
                                </h1>
                            </div>
                            <div className="text-gray-700 flex-none justify-end">
                                <label htmlFor="total">Total:</label>
                                <input
                                    id="data-runner-scale"
                                    className="border rounded py-2 px-3 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    defaultValue={dataRunners.items.length}
                                    size="5" />
                                <button
                                    id="data-runner-scale-button"
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2"
                                    onClick={() => {handleDataRunnerScale()}}>
                                    Scale
                                </button>
                            </div>
                        </div>
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Pod
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Version
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Started
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Restarts
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Phase
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataRunners.items.map((d, index) => {
                                    var started_at = (d.status.containerStatuses && d.status.containerStatuses[0])
                                        ? d.status.containerStatuses[2].state.running.startedAt
                                        : d.status.startTime;
                                    var restart_count = (d.status.containerStatuses && d.status.containerStatuses[0])
                                        ? d.status.containerStatuses[2].restartCount
                                        : 0;
                                    var phase = (d.status.phase === 'Failed')
                                        ? <span className="text-red-500">{d.status.phase}</span>
                                        : d.status.phase;
                                    return (
                                        <tr className="border-b" key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {d.metadata.name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {d.metadata.labels.version}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                <Moment withTitle={started_at} fromNow>
                                                    {started_at}
                                                </Moment>
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {restart_count}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {phase}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <br /><br />
            </div>
        );
    }

    return (
        <div className="logs-container">
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Runners</h1>
                </div>
                <div className="flex-auto text-right"></div>
            </div>
            <div>
                <div className="overflow-hidden p-3">
                    <Spinner />
                </div>
            </div>
        </div>
    )
};

export default Runners;
