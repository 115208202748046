import { Link } from "react-router-dom";
import Spinner from "./Spinner";

const Logs = (props) => {
    if (props.logs && props.logs.data && props.logs.data.length > 0) {
        return (
            <div className="logs-container">
                <div className="flex justify-between p-3">
                    <div className="flex-auto">
                        <h1 className="text-2xl font-bold text-gray-900">Logs</h1>
                    </div>
                    <div className="flex-auto text-right"></div>
                </div>
                <div>
                    <div className="overflow-hidden p-3">
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Date
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Email
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Message
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.logs.data.map((log, index) => {
                                    var bg = (index % 2 === 0) ? 'bg-gray-100' : '';

                                    return (
                                        <tr className={`border-b ${bg}`} key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {log.created_at}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {log.email || '--'}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {log.message}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot className="border-t bg-black text-white">
                                <tr>
                                    <td colSpan="3" className="text-sm font-medium px-3 py-2 text-right">
                                        <Link to="/logs-all" style={{ display: "flex", justifyContent: "right" }}>
                                            View {props.logs.total} total logs &rarr;
                                        </Link>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="logs-container">
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Logs</h1>
                </div>
                <div className="flex-auto text-right"></div>
            </div>
            <div>
                <div className="overflow-hidden p-3">
                    {(props.logs && props.logs.length === 0)
                        ? <Spinner />
                        : "No Logs Found"
                    }
                </div>
            </div>
        </div>
    )
};

export default Logs;
