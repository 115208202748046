import { FaHome, FaDatabase, FaLock, FaTachometerAlt, FaRunning, FaPencilAlt, FaPaperclip, FaCalendar, FaBed, FaScrewdriver, FaArrowLeft, FaHandHolding } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import React, { useEffect } from "react";
import { isLoggedIn, killAllTimers } from "./utils";

export default function Root() {
    const [environment, setEnvironment] = React.useState("production");

    useEffect(() => {
        const fetchAll = () => {
            fetch('/environment')
                .then((response) => response.json())
                .then((data) => {
                    setEnvironment(data.env);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }

        fetchAll(); // Initial fetch
    }, []);

    isLoggedIn().then((data) => {
        if (data !== undefined && !data.logged_in) {
            killAllTimers(); // Kill all timers to prevent memory leaks
            window.location.href = '/dashboard#/login';
        }
    });

    return (
        <div className="min-h-screen flex">
            <aside className="h-screen sticky top-0 bg-black text-white min-h-screen">
                <Link to="/">
                    <img src="https://www.locally.com/img/locally-avatar.png" width="100" alt="Logo" />
                </Link>
                <hr />
                <nav className="mt-3">
                    <ul>
                        <li className="p-5">
                            <Link to="/" data-tooltip="Home" style={{display: "flex", justifyContent: "center"}}>
                                <FaHome size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/custom" data-tooltip="Custom Build Deploys" style={{display: "flex", justifyContent: "center"}}>
                                <FaTachometerAlt size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/queues" data-tooltip="Queues" style={{display: "flex", justifyContent: "center"}}>
                                <FaDatabase size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/semaphores" data-tooltip="Semaphores" style={{display: "flex", justifyContent: "center"}}>
                                <FaLock size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/logs-all" data-tooltip="Logs" style={{display: "flex", justifyContent: "center"}}>
                                <FaPencilAlt size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/domains" data-tooltip="Domains" style={{display: "flex", justifyContent: "center"}}>
                                <FaPaperclip size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/crons" data-tooltip="Crons" style={{display: "flex", justifyContent: "center"}}>
                                <FaCalendar size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/mysql_slow_query_logs" data-tooltip="MySQL Slow Query" style={{display: "flex", justifyContent: "center"}}>
                                <FaBed size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/service_status" data-tooltip="Service Status" style={{display: "flex", justifyContent: "center"}}>
                                <FaScrewdriver size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/runners" data-tooltip="Runners" style={{display: "flex", justifyContent: "center"}}>
                                <FaRunning size="2em" />
                            </Link>
                        </li>
                        <li className="p-5">
                            <Link to="/redis_reserved" data-tooltip="Redis" style={{display: "flex", justifyContent: "center"}}>
                                <FaHandHolding size="2em" />
                            </Link>
                        </li>
                    </ul>
                </nav>

                <footer className="sticky top-[100vh]">
                    <a href="/logout" style={{display: "flex", justifyContent: "center", paddingBottom: "15px"}} alt="Logout">
                        <FaArrowLeft size="2em" />
                    </a>
                </footer>
            </aside>

            <main className="flex-1 min-w-0 overflow-auto">
                {environment !== "production" &&
                    <div className="p-3 bg-yellow-100">
                        {environment} environment
                    </div>
                }
                <div id="detail">
                    <Outlet />
                </div>
            </main>
        </div>
    );
}
