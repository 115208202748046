export default function LatestVersion(props) {
    return (
        <div className="flex gap-3">
            <div className="flex-auto">
                <div className="bg-green-600 rounded shadow pl-10 pr-10 pt-4 pb-4">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pl-1 pr-4"><i className="fa fa-wallet fa-2x fa-fw fa-inverse"></i></div>
                        <div className="flex-1 text-right">
                            <h5 className="text-white">Production</h5>
                            <h3 className="text-white text-3xl">
                                {(props.version.length > 0)
                                    ? <strong>{props.version}</strong>
                                    : '...'
                                }
                                <span className="text-green-400"><i className="fas fa-caret-down"></i></span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-auto">
                <div className="bg-blue-600 rounded shadow pl-10 pr-10 pt-4 pb-4">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pl-1 pr-4"><i className="fa fa-wallet fa-2x fa-fw fa-inverse"></i></div>
                        <div className="flex-1 text-right">
                            <h5 className="text-white">Staging</h5>
                            <h3 className="text-white text-3xl">
                                {(props.versionStaging.length > 0)
                                    ? <strong>{props.versionStaging}</strong>
                                    : '...'
                                }
                                <span className="text-blue-400"><i className="fas fa-caret-down"></i></span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
