import React from 'react';

class QueueForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        if (event.target.name.value === '') {
            alert('Please select a queue.');
            event.preventDefault();
            return;
        }

        if (event.target.threshold.value === '') {
            alert('Please enter a threshold.');
            event.preventDefault();
            return;
        }

        if (isNaN(event.target.threshold.value)) {
            alert('Threshold must be a number.');
            event.preventDefault();
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch('/api/update-queue/' + event.target.name.value + '/' + event.target.threshold.value, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.result === 'ok') {
                    console.log('Queue updated successfully.');
                    window.location.reload();
                } else {
                    console.log('Error: ' + data.error);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });

        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} method="post" action="/api/update-queue">
                <select name="name" id="name" className="border border-gray-300 rounded-md p-2 m-2">
                    {this.props.queues.map((queue, index) => {
                        return (
                            <option key={index} value={queue.NAME}>{queue.NAME}</option>
                        );
                    })}
                </select>
                <input type="text" name="threshold" id="threshold" className="border border-gray-300 rounded-md p-2 m-2" width="10" autoComplete="off" />
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">Update</button>
            </form>
        );
    }
}

export default QueueForm;
