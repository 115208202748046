import { useState, useEffect } from "react";
import Moment from "react-moment";
import Spinner from "./Spinner";

const SlowSQLLog = () => {
    const [slow, setSlow] = useState([]);
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        fetch('/api/mysql_slow_query_logs')
            .then((response) => response.json())
            .then((data) => {
                setSlow(data);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    if (!spinner && slow.length > 0) {
        return (
            <div className="logs-container">
                <div className="flex justify-between p-3">
                    <div className="flex-auto">
                        <h1 className="text-2xl font-bold text-gray-900">Slow Queries</h1>
                    </div>
                    <div className="flex-auto text-right"></div>
                </div>
                <div>
                    <div className="overflow-hidden p-3">
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Date
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Query
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {slow.map((d, index) => {
                                    return (
                                        <tr className={`border-b`} key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r" width="150">
                                                <Moment withTitle={d.timestamp} fromNow>
                                                    {d.timestamp}
                                                </Moment>
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                <textarea
                                                    rows="10"
                                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    {d.textPayload}
                                                </textarea>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="logs-container">
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Slow Queries</h1>
                </div>
                <div className="flex-auto text-right"></div>
            </div>
            <div>
                <div className="overflow-hidden p-3">
                    {(spinner)
                        ? <Spinner />
                        : "No Slow Queries Found"
                    }
                </div>
            </div>
        </div>
    )
};

export default SlowSQLLog;
