import { useState, useEffect } from "react";
import Spinner from "./Spinner";

const Crons = () => {
    const [crons, setCrons] = useState([]);
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        const crons = () => {
            fetch('/api/crons')
                .then((response) => response.json())
                .then((data) => {
                    setCrons(data);
                    setSpinner(false);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        };

        crons(); // Initial fetch
        const intervalId = setInterval(crons, 15000);
        return () => {
            clearInterval(intervalId);
        };
    }, [spinner]);

    if (!spinner && crons.length > 0) {
        return (
            <div className="logs-container">
                <div className="flex justify-between p-3">
                    <div className="flex-auto">
                        <h1 className="text-2xl font-bold text-gray-900">Cron Jobs</h1>
                    </div>
                    <div className="flex-auto text-right"></div>
                </div>
                <div>
                    <div className="overflow-hidden p-3">
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Name
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Description
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Schedule
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Suspended
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Time Since Last Run
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {crons.map((d, index) => {
                                    return (
                                        <tr className={`border-b hover:opacity-75 ${d.suspend === 'False' ? 'bg-green-300' : 'bg-red-300'}`} key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {d.name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {d.description}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {d.schedule}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {d.suspend}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                {d.last_schedule}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="logs-container">
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Cron Jobs</h1>
                </div>
                <div className="flex-auto text-right"></div>
            </div>
            <div>
                <div className="overflow-hidden p-3">
                    {(spinner)
                        ? <Spinner />
                        : "No Cron Jobs Found"
                    }
                </div>
            </div>
        </div>
    )
};

export default Crons;
