export default function ScaleModal(props) {
    if (!props.showModal) {
        return null;
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">Scale {props.version}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700">
                                        API Pods
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="API Pods"
                                        name="api"
                                        id="api"
                                        defaultValue={props.podCounts.api.current} />
                                </div>
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700">
                                        Headless Pods
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Headless Pods"
                                        name="headless"
                                        id="headless"
                                        defaultValue={props.podCounts.headless.current} />
                                </div>
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700">
                                        Hosted Pods
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Hosted Pods"
                                        name="hosted"
                                        id="hosted"
                                        defaultValue={props.podCounts.hosted.current} />
                                </div>
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700">
                                        Product Pods
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Product Pods"
                                        name="product"
                                        id="product"
                                        defaultValue={props.podCounts.product.current} />
                                </div>
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700">
                                        Staging Pods
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Staging Pods"
                                        name="staging"
                                        id="staging"
                                        defaultValue={props.podCounts.staging.current} />
                                </div>
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700">
                                        Station Pods
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Station Pods"
                                        name="station"
                                        id="station"
                                        defaultValue={props.podCounts.station.current} />
                                </div>
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700">
                                        Stores Pods
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Stores Pods"
                                        name="stores"
                                        id="stores"
                                        defaultValue={props.podCounts.stores.current} />
                                </div>
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700">
                                        Web Pods
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Web Pods"
                                        name="web"
                                        id="web"
                                        defaultValue={props.podCounts.web.current} />
                                </div>
                            </div>
                        </div>
                        {/* footer */}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.closeModal()}>
                                Close
                            </button>
                            <button
                                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.handleScaleClickSave(props.version)}>
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}
