import { useState, useEffect } from "react";
import Spinner from "./Spinner.js";

const RedisReserved = () => {
    const [reserved, setReserved] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [lastUpdated, setLastUpdated] = useState(Date.now());
    const [dataRunners, setDataRunners] = useState([]);
    const [fileRunners, setFileRunners] = useState([]);
    const [avgTimeInQueue, setAvgTimeInQueue] = useState(0);

    useEffect(() => {
        const methods = () => {
            fetch('/api/redis_reserved')
                .then((response) => response.json())
                .then((data) => {
                    setLastUpdated(Date.now());

                    // sort by queue time
                    data.sort((a, b) => {
                        return a.data.added_to_final_queue_time - b.data.added_to_final_queue_time;
                    });

                    setReserved(data);
                    setSpinner(false);

                    var total = 0;
                    for (var i = 0; i < data.length; i++) {
                        total += ((Date.now() * 1000 / 1000 / 1000) - data[i].data.added_to_final_queue_time) / 60;
                    }

                    setAvgTimeInQueue((total / data.length).toFixed(2));
                })
                .catch((err) => {
                    console.log(err.message);
                });

            fetch('/api/data_runners')
                .then((response) => response.json())
                .then((data) => {
                    setDataRunners(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });

            fetch('/api/file_runners')
                .then((response) => response.json())
                .then((data) => {
                    setFileRunners(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        };

        methods(); // Initial fetch
        const intervalId = setInterval(methods, 5000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const noReserved = (!spinner)
        ? 'No reserved found.'
        : <Spinner />

    return (
        <div className="queues-container">
            <div className="flex justify-between p-3">
                <div className="flex-auto">
                    <h1 className="text-2xl font-bold text-gray-900">Redis (Reserved Tasks)</h1>
                </div>
                <div className="flex-auto text-right">
                    <strong>Last Updated:</strong> {new Date(lastUpdated).toLocaleString()} CST<br />
                    <strong>Total Reserved:</strong> {reserved.length} (avg: {avgTimeInQueue} mins) | <strong>Total Data Runners:</strong> {dataRunners && dataRunners.items && dataRunners.items.length | 0} | <strong>Total File Runners:</strong> {fileRunners && fileRunners.items && fileRunners.items.length | 0}
                </div>
            </div>
            {(reserved.length === 0)
                ? <div className="text-center">{noReserved}</div>
                : <div>
                    <div className="overflow-hidden p-3 mb-10">
                        <table className="min-w-full border">
                            <thead className="border-b bg-black text-white">
                                <tr>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left" width="100">
                                        #
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left" width="200">
                                        Queued
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left" width="200">
                                        Task
                                    </th>
                                    <th scope="col" className="text-sm font-medium px-3 py-2 border-r text-left">
                                        Data
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {reserved.map((r, index) => {
                                    var bg = (index % 2 === 0) ? 'bg-gray-100' : '';
                                    var queue_time = (((Date.now() * 1000 / 1000 / 1000) - r.data.added_to_final_queue_time) / 60).toFixed(2);
                                    return (
                                        <tr className={`border-b ${bg}`} key={index}>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {index + 1}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {queue_time} min(s) ago
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap border-r">
                                                {r.job}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap">
                                                <pre>
                                                    {JSON.stringify(r.data, null, 4)}
                                                </pre>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
};

export default RedisReserved;
